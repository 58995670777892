import { Injectable } from '@angular/core';
import { SubSellerDto } from '../../../new-models/us-models/v2';
import { CrudService } from '../../crud.service';


@Injectable({
  providedIn: 'root'
})
export class SubSellersService extends CrudService<SubSellerDto> {
  basePath = '/v2/stores/sub-sellers';

  constructor() {
    super();
  }
}
