import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeliveryRating, DeliveryRatingRequest, Order } from '../../../new-models/us-models/v2';
import { CrudService } from '../../crud.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends CrudService<Order> {
  basePath = '/v2/basket/orders';

  constructor() {
    super();
  }

  rateDelivery(id: string, rating: DeliveryRatingRequest): Observable<DeliveryRating> {
    return this.apiService.post(`${this.basePath}/${id}/delivery/rating`, rating)
      .pipe(
        map(resp => resp.data),
      );
  }
}
