import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { computed, inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  breakpointState: Signal<BreakpointState>;
  breakpointObserver = inject(BreakpointObserver);
  isMobile: Signal<boolean>;

  constructor() {
    this.breakpointState = toSignal(this.breakpointObserver
      .observe([
          Breakpoints.XSmall,
          Breakpoints.Small,
          Breakpoints.Medium,
          Breakpoints.Large,
        ]
      ));

    const mobileState = toSignal(this.breakpointObserver.observe(Breakpoints.XSmall));

    this.isMobile = computed(() => mobileState()?.matches);
  }

  getItemsInRow(map: Map<string, number>, defaultVal: number): Signal<number> {
    return computed(() => {
      const breakpoints = this.breakpointState().breakpoints;

      return Object.keys(breakpoints)
        .reduce((prev, v) => breakpoints[ v ] ? map.get(v) : prev, defaultVal);
    });
  }
}
