import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiGetParams, ApiResponse } from '../../../models';
import { WalletDto, WalletTransactionDto } from '../../../new-models/us-models/v2';
import { CrudService } from '../../crud.service';

@Injectable({
  providedIn: 'root'
})
export class StoreCreditService extends CrudService<WalletDto> {
  basePath = '/v2/customers/wallets';

  constructor() {
    super();
  }

  allTransactionsWithMeta(id: string, params?: ApiGetParams): Observable<ApiResponse<WalletTransactionDto[]>> {
    return this.apiService.get(`${this.basePath}/${id}/transactions`, params)
  }
}
