import { computed, inject, Injectable, signal, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { Address, ApiResponse } from '../../../models';
import { MakePaymentResponse } from '../../../new-models/us-models';
import {
  Order,
  OrderDeliveryProvider,
  OrderDto,
  OrderDtoItem,
  OrderLineItem,
  OrderLocation,
  OrderPaymentDto,
  OrderShippingDto,
  OrderShippingMethod,
  OrderStore,
  PaymentMethodsResponse,
  ShippingMethodsDto,
  ShippingQuoteData
} from '../../../new-models/us-models/v2';
import { StoreCartService } from '../../../new-services/us-services';
import { CrudService } from '../../crud.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class CartService extends CrudService<Order> {
  basePath = '/v2/basket/cart';
  currentUrl: Signal<string>;
  flatLineItems: Signal<OrderLineItem[]>;
  hasDeliveryAddress: Signal<boolean>;
  isLocalAddress: Signal<boolean>;
  hasErrors: Signal<boolean>;

  isUpdating = signal(false);

  #shippingMethods = signal<OrderShippingMethod[]>([]);
  shippingMethodsLoaded = signal<boolean>(false);
  shippingMethodsLoaded$ = toObservable(this.shippingMethodsLoaded);

  #authService = inject(AuthService);
  #storeCartService = inject(StoreCartService);

  constructor() {
    super();

    this.flatLineItems = computed(() => {
      return this.current()?.stores.flatMap((store: OrderStore) =>
        store.locations.flatMap((location: OrderLocation) => location.lineItems)
      );
    });

    this.currentUrl = computed(() => `${this.basePath}/${this.current().id}`);
    this.hasDeliveryAddress = computed(() => !!this.current()?.deliveryDetails);
    this.isLocalAddress = computed(() => this.current()?.deliveryDetails?.isLocalAddress);
    this.hasErrors = computed(() => this.flatLineItems()?.some(i => i.hasError));
  }

  set shippingMethods(v: OrderShippingMethod[]) {
    this.#shippingMethods.set(v);
  }

  get shippingMethods(): Signal<OrderShippingMethod[]> {
    return this.#shippingMethods.asReadonly();
  }

  getShippingMethodsByStore(storeId: string): Signal<OrderShippingMethod> {
    return computed(() => this.shippingMethods().find(m => m.storeId === storeId));
  }

  getDeliveryProvidersByStore(storeId: string): Signal<OrderDeliveryProvider[]> {
    return computed(() => {
      const method = this.getShippingMethodsByStore(storeId)();
      const type = this.isLocalAddress() ? 'domestic' : 'international';

      return method ? method[ type ].providers : [];
    });
  }

  getOpenOrder(): Observable<Order> {
    return this.apiService.get(`${this.basePath}/open`)
      .pipe(
        map((resp: ApiResponse) => resp?.data),
      );
  }

  getOrCreateCurrentOrder(): Observable<Order> {
    return this.getLastOrder()
      .pipe(
        switchMap(order =>
          order?.orderStatus === 'Draft' ? this.updateOrderFromCart(order.id) : this.createOrderFromCart()
        ),
        tap(order => {
          this.setCurrentOrder(order);
        }),
      );
  }

  createOrder(orderDto: OrderDto): Observable<Order> {
    return this.apiService.post(this.basePath, orderDto)
      .pipe(
        map((resp: ApiResponse) => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
          this.updateCart();
        }),
      );
  }

  changeItemQty(itemId: string, quantity: number): Observable<Order> {
    const itemsDto = this.flatLineItems()
      .map(item => item.id === itemId ? { ...item, quantity } : item)
      .map(lineItemToDto);

    return this.updateOrder(itemsDto);
  }

  // note: need it instead of changeItemQty to work correctly with separated discounted/free and paid products
  increaseItemQty(itemId: string, increaseByQuantity: number): Observable<Order> {
    const itemsDto = this.flatLineItems()
      .map(item => item.id === itemId ? { ...item, quantity: item.quantity + increaseByQuantity } : item)
      .map(lineItemToDto);

    return this.updateOrder(itemsDto);
  }

  // note: need it instead of changeItemQty to work correctly with separated discounted/free and paid products
  reduceItemQty(itemId: string, reduceByQuantity: number, reduceInSales?: boolean): Observable<Order> {
    const itemsDto = this.flatLineItems()
      .map(item =>
        item.id === itemId
          ? {
            ...item,
            quantity: item.quantity - reduceByQuantity,
            ...(reduceInSales && item.sales?.length && {
              sales: item.sales
                .map(sale => ({ ...sale, discountQuantity: sale.discountQuantity - reduceByQuantity }))
                .filter(sale => sale.discountQuantity > 0),
            }),
          }
          : item
      )
      .filter(item => item.quantity > 0)
      .map(lineItemToDto);

    return this.updateOrder(itemsDto);
  }

  createOrderFromCart(addressId = null): Observable<Order> {
    const cart = this.#storeCartService.cart();

    if (cart.lineItems.length === 0) {
      return of(null);
    }

    return this.createOrder({
      lineItems: cart.lineItems,
      ...(addressId && { addressId }),
    });
  }

  updateOrderFromCart(id?: string): Observable<Order> {
    const cart = this.#storeCartService.cart();

    return this.updateOrder(cart.lineItems, id);
  }

  updateOrder(lineItems: OrderDtoItem[], id?: string): Observable<Order> {
    // handled on BE side
    //const filteredItems = lineItems.filter(item => item.quantity > 0);
    //
    //if (filteredItems.length === 0) {
    //  return this.closeOrder();
    //}

    this.isUpdating.set(true);

    return this.update<OrderDto>(id ?? this.current().id, { lineItems })
      .pipe(
        tap(order => {
          this.setCurrentOrder(order);
          this.updateCart();
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  reserveOrder(): Observable<Order> {
    this.isUpdating.set(true);

    return this.apiService.put(`${this.currentUrl()}/reserve`, {})
      .pipe(
        map(resp => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  setDeliveryAddress(addressId: string): Observable<Order> {
    this.isUpdating.set(true);

    return this.apiService.put(`${this.currentUrl()}/delivery-address`, { addressId })
      .pipe(
        map(resp => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  setBillingAddress(address: Address): Observable<Order> {
    this.isUpdating.set(true);

    return this.apiService.put(`${this.currentUrl()}/billing-address`, address)
      .pipe(
        map(resp => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  getShippingMethods(id?: string): Observable<OrderShippingMethod[]> {
    const baseUrl = id ? `${this.basePath}/${id}` : this.currentUrl();

    return this.apiService.get(`${baseUrl}/shipping-methods`)
      .pipe(
        map(resp => resp.data),
      );
  }

  initShippingMethods(): void {
    if (this.shippingMethods().length > 0) {
      return;
    }

    this.shippingMethodsLoaded.set(false);

    this.getShippingMethods()
      .pipe(
        finalize(() => this.shippingMethodsLoaded.set(true)),
      )
      .subscribe(methods => this.shippingMethods = methods);
  }

  applyVoucher(storeId: string, voucherCode: string): Observable<Order> {
    this.isUpdating.set(true);

    return this.apiService.patch(`${this.currentUrl()}/apply-voucher`, { storeId, voucherCode })
      .pipe(
        map((resp: ApiResponse) => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  removeVoucher(storeId: string): Observable<Order> {
    this.isUpdating.set(true);

    return this.apiService.patch(`${this.currentUrl()}/remove-voucher`, { storeId })
      .pipe(
        map((resp: ApiResponse) => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  //applyCreditsByStore(amount: number, storeId: string): Observable<Order> {
  //  return this.applyCredits({ amount, storeId });
  //}
  //
  //applyCreditsByLocation(amount: number, storeId: string, locationId: string): Observable<Order> {
  //  return this.applyCredits({ amount, storeId, locationId });
  //}

  applyCredits(amount: number, storeId: string, locationId: string): Observable<Order> {
    this.isUpdating.set(true);

    const data = { amount, storeId, ...(locationId && { locationId }) }

    return this.apiService.patch(`${this.currentUrl()}/apply-credit`, data)
      .pipe(
        map((resp: ApiResponse) => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  removeCreditStore(storeId: string): Observable<Order> {
    this.isUpdating.set(true);

    return this.apiService.patch(`${this.currentUrl()}/remove-credit`, { storeId })
      .pipe(
        map((resp: ApiResponse) => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  setShippingMethod(data: ShippingMethodsDto): Observable<Order> {
    this.isUpdating.set(true);

    return this.apiService.patch(`${this.currentUrl()}/set-shipping-method`, data)
      .pipe(
        map(resp => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  getDeliveryQuotes(storeId: string, locationId: string, providerId: string = null): Observable<ShippingQuoteData> {
    const params = { storeId, locationId, ...(providerId && { providerId }) };
    return this.apiService.get(`${this.currentUrl()}/shipping-quotes`, params)
      .pipe(
        map(resp => resp.data),
      );
  }

  manageShippingNote(note: string, storeId: string, locationId: string): Observable<Order> {
    this.isUpdating.set(true);

    return this.apiService.patch(`${this.currentUrl()}/shipping/note`, { storeId, locationId, note })
      .pipe(
        map(resp => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  setOrderShipping(data: OrderShippingDto): Observable<Order> {
    this.isUpdating.set(true);

    return this.apiService.patch(`${this.currentUrl()}/shipping`, data)
      .pipe(
        map(resp => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  //setDeliveryNote(note: string, locationId): Observable<Order> {
  //
  //}

  //initShippingMethods(id?: string): void {
  //  if (this.shippingMethods().length > 0) {
  //    return;
  //  }
  //
  //  this.getShippingMethods(id)
  //    .subscribe(methods => {
  //      this.shippingMethods.set(methods);
  //      this.shippingMethodsLoaded.set(true);
  //    });
  //}

  getPaymentMethods(): Observable<PaymentMethodsResponse> {
    return this.apiService.get(`${this.currentUrl()}/payment-methods`)
      .pipe(
        map(resp => resp.data),
      );
  }

  makePayment(data: OrderPaymentDto): Observable<MakePaymentResponse> {
    return this.apiService.put(`${this.currentUrl()}/make-payment`, data)
      .pipe(
        map(resp => resp.data),
        //tap(resp => {
        //  this.makePaymentResponse = resp;
        //  this.makePaymentResponseLoaded = true;
        //}),
      );
  }

  manageProductNote(itemId: string, note: string): Observable<Order> {
    this.isUpdating.set(true);

    return this.apiService.patch(`${this.currentUrl()}/line-items/${itemId}`, { note })
      .pipe(
        map(resp => resp.data),
        tap(order => {
          this.setCurrentOrder(order);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  closeOrder(): Observable<null> {
    this.isUpdating.set(true);

    return this.apiService.delete(`${this.currentUrl()}/close`)
      .pipe(
        tap(() => {
          this.setCurrentOrder(null);
        }),
        finalize(() => this.isUpdating.set(false)),
      );
  }

  // todo make private (needed public on apply sale page)
  getLastOrder(): Observable<Order | null> {
    if (this.#authService.isLoggedUser()) {
      return this.getOpenOrder();
    }

    if (this.#storeCartService.isLastOrderValid) {
      return this.find(this.#storeCartService.lastOrderId)
        .pipe(
          catchError(() => {
            this.#storeCartService.removeLastOrder();
            return of(null);
          }),
        );
    }

    return of(null);
  }

  private setCurrentOrder(order: Order): void {
    this.current = order;
    this.#storeCartService.setLastOrderData(order);
  }

  private updateCart(): void {
    this.#storeCartService.updateCartFromOrder(this.current());
  }
}

function lineItemToDto(orderItem: OrderLineItem): OrderDtoItem {
  return {
    productId: orderItem.productId,
    variationId: orderItem.variationId,
    quantity: orderItem.quantity,
    //note: orderItem.note,
    sales: orderItem.sales.map(sale => ({ id: sale.id, discountQuantity: sale.discountQuantity })),
  };
}
